// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "../stylesheets/tailwindcss.scss"
import 'medium_uploader.js'
global.$ = global.jQuery = require('jquery')
require('jquery-ui')
require('blueimp-file-upload')
require('lazyload')

Rails.start()
Turbolinks.start()
ActiveStorage.start()