document.addEventListener('turbolinks:load', () => {
  var targetE = document.getElementById('contents-uploader');
  if(targetE){
    $('.directUpload').find("input:file").each(function(i, elem) {
      var fileInput    = $(elem);
      var form         = $(fileInput.parents('form:first'));
      var submitButton = form.find('input[type="submit"]');
      var progressBar  = $("<div class='bar whitespace-nowrap'></div>");
      var barContainer = $("<div class='progress'></div>").append(progressBar);
      var count = 0;
      fileInput.after(barContainer);
      fileInput.fileupload({
        fileInput:       fileInput,
        url:             form.data('url'),
        type:            'POST',
        autoUpload:       true,
        formData:         form.data('form-data'),
        paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
        dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
        replaceFileInput: false,
        progressall: function (e, data) {
          var progress = parseInt(data.loaded / data.total * 100, 10);
          progressBar.css('width', progress + '%')
        },
        start: function (e) {
          var count = 0;
          submitButton.prop('disabled', true);
          var content_type_str = fileInput[0].files[0].type.split('/')[0];
          if(content_type_str=='image'){
          }else if(content_type_str=='audio'){
          }else if(content_type_str=='video'){
          }else{
            alert('画像または音声のみアップロード可能です。ファイルをご確認ください。');
            return location.reload();
          }

          progressBar.
            css('background', 'green').
            css('display', 'block').
            css('width', '0%').
            text("アップロード中");
        },
        done: function(e, data) {
          submitButton.prop('disabled', false);
          progressBar.
            text("アップロード完了");
          // extract key and generate URL from response
          var key   = $(data.jqXHR.responseXML).find("Key").text();
          var url   = '//' + form.data('host') + '/' + key;

          // create hidden field
          var input1 = $("<input />", { type:'hidden', name: 'original[]', value: url });
          var fileObject = $(data.files)[0];
          var newFileObject  = {
            'name': fileObject.name,
            'size': fileObject.size,
            'type': fileObject.type
          };
          var input2 = $("<input />", { type:'hidden', name: 'medium_files[]', value: JSON.stringify(newFileObject) });
          form.prepend(input1);
          form.prepend(input2);
          if(count + 1 == $("#medium_files")[0].files.length){
            fileInput.remove();
            $('#uploader-send-button')[0].click();
            $('#contents-uploader').html('データの保存中です。リロードなどの操作はせずにそのままお待ち下さい。');
          }
          count ++;
        },
        fail: function(e, data) {
          submitButton.prop('disabled', false);

          progressBar.
            css("background", "red").
            text("Failed");
        }
      });
    });
  }
});